<template>
  <sub-h5-login v-if="$utils.isPhone()" />
  <sub-pc-login v-else />
</template>
<script>
  import subH5Login from '@/views/submodule/h5/guide/login'
  import subPcLogin from '@/views/submodule/pc/guide/login'

  export default {
    components: {
      subH5Login,
      subPcLogin
    }
  }
</script>
