<template>
  <div class="head-navs-box">
    <div class="head-navs clearfix">
      <div class="img-logo">
        <img v-if="guideInfo.logoUrl" :src="guideInfo.logoUrl">
      </div>
      <ul class="menu-list">
        <li v-for="(obj, key) in menuList" :key="key" class="el-sl" :class="{active: activeId === `#${obj.id}`}">
          <a v-if="obj.linkType === 1" :href="obj.linkUrl | TOLINK" target="_blank">{{ obj.name }}</a>
          <a v-else href="javascript:;" @click="goAnchor(`#${obj.id}`, obj.moduleId)">{{ obj.name }}</a>
        </li>
      </ul>
      <el-dropdown :show-timeout="0" :hide-timeout="0" class="pc-dropdown-menu">
        <span class="el-dropdown-link">
          <span class="username el-sl">{{ guideUser?.name || '请登录' }}</span>
          <!-- <i class="h5pp h5pp-icon-arrow-down-entity"></i> -->
        </span>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

  export default {
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem
    },
    props: {
      menuList: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        activeId: ''
      }
    },
    computed: {
      ...mapGetters(['guideInfo']),
      ...mapGetters(['guideUser']),
      id() {
        return this.$route.query.id; 
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        if(this.guideInfo) {
          window.document.title = this.guideInfo.title;
        }
        let hashId = this.$route.hash || this.activePage;
        this.goAnchor(hashId);
      },
      goAnchor(index, id) {
        this.activeId = index;
        if(id && document.getElementById(id)) {
          document.getElementById(id)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      },
    }
  }
</script>