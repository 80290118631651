<template>
  <div class="item-guide-form-box">
    <el-form ref="resForm" :model="resForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" labelAlign="left" class="item-resume-form" @submit.native.prevent>
      <div class="item-guide-form-title module-width">
        <div class="module-title">
          <div class="cn">{{ moudleData.moduleDto.name }}</div>
          <div v-if="moudleData.moduleDto.englishDesc" class="en">{{ moudleData.moduleDto.englishDesc }}</div>
        </div>
        <div v-if="isMulti" class="btns"><a href="javascript:;" @click="onAddItem"><i class="h5pp h5pp-icon-append"></i>添加</a></div>
      </div>
      <template v-if="isMulti">
        <div v-for="(obj, index) in resForm.expArr" :key="index" class="item-guide-form-content module-width" :class="{ 'active': activeIndex === index}">
          <template v-for="(item, key) in moudleData.fields">
            <sub-custom-field v-if="item.isCheck" :ref="`custom-${item.fieldId}-${index}`" :key="`${resForm.expArr.length - index}——${key}`" :fieldData="item" :itemData="getItemData(obj, item.fieldId)" @update="updateField(index, $event)" />
          </template>
          <div v-if="isMulti && resForm.expArr.length > 1" class="item-guide-trash" >
            <a href="javascript:;" @mouseleave="activeIndex = ''" @mouseenter="activeIndex = index" @click="onRemoveItem(index)"><i class="h5pp h5pp-icon-delete"></i>删除</a>
          </div>
        </div>
      </template>
      <div v-else class="item-guide-form-content module-width">
        <template v-for="(item, key) in moudleData.fields">
          <sub-custom-field v-if="item.isCheck" :ref="`custom-${item.fieldId}`" :key="key" :fieldData="item" :itemData="getItemData(resForm.expArr[0], item.fieldId)" @update="updateField(0, $event)" />
        </template>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { Form, Button} from 'element-ui'
  import subCustomField from '../customField'

  export default {
    components: {
      [Form.name]: Form,
      [Button.name]: Button,
      subCustomField
    },
    props: {
      moudleData: Object,
      subResData: Array
    },
    data() {
      return {
        resForm: {
          expArr: [{}]
        },
        activeIndex: ''
      }
    },
    computed: {
      isMulti() {
        return this.moudleData.moduleDto.multiFlag === 1;
      }
    },
    methods: {
      getItemData(obj, id) {
        if(obj && obj[id]) {
          return this.$utils.deepClone(obj[id]);
        }
        return '';
      },
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {};
        this.resForm.expArr.unshift(newItem);
      },
      updateField(index, data) {
        const dto = data.data.fieldDto;
        if(!this.resForm.expArr[index]) {
          this.resForm.expArr[index] = {};
        }
        this.resForm.expArr[index][dto.id] = {
          fieldId: dto.id,
          fieldType: dto.type,
          value: dto.type > 3 ? JSON.stringify(data.value) : data.value
        }
      },
      validateCustom() {
        const validateArr = Object.keys(this.$refs).filter(key => key.includes('custom')).map(item => this.validateSubItem(item));
        if(validateArr.length) {
          return Promise.all(validateArr).then(res => {
            if(res.includes(false)) {
              return false;
            }
            return true;
          });
        } else {
          return Promise.resolve(true);
        }
      },
      validateSubItem(formName) {
        return new Promise(resolve => {
          if(this.$refs[formName] && this.$refs[formName][0]) {
            this.$refs[formName][0].$refs.customForm.validate(valid => {
              resolve(valid);
            });
          } else {
            resolve();
          }
        });
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            this.resForm.expArr = [];
            newVal.forEach(item => {
              this.resForm.expArr.push(this.$utils.deepClone(item));
            });
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>