<template>
  <div class="item-resume-form-box">
    <h3>{{moudleData.moduleDto.name}}</h3>
    <template v-for="(item, key) in moudleData.fields">
      <sub-custom-field v-if="item.isCheck" :ref="`custom-${item.fieldId}`" :key="key" :fieldData="item" :itemData="getItemData(resForm.expArr[0], item.fieldId)" @update="updateField(0, $event)" />
    </template>
  </div>
</template>

<script>
  import subCustomField from '../customField'

  export default {
    components: {
      subCustomField
    },
    props: {
      moudleData: Object,
      subResData: Array
    },
    data() {
      return {
        resForm: {
          expArr: []
        }
      }
    },
    methods: {
      getItemData(obj, id) {
        if(obj && obj[id]) {
          return this.$utils.deepClone(obj[id]);
        }
        return '';
      },
      onAddItem() {
        if(this.resForm.expArr.length >= 20) {
          this.$message.error('最多添加20条');
          return
        }
        const newItem = {};
        this.resForm.expArr.unshift(newItem);
      },
      updateField(index, data) {
        const dto = data.data.fieldDto;
        if(!this.resForm.expArr[index]) {
          this.resForm.expArr[index] = {};
        }
        this.resForm.expArr[index][dto.id] = {
          fieldId: dto.id,
          fieldType: dto.type,
          value: dto.type > 3 ? JSON.stringify(data.value) : data.value
        }
      },
      validateCustom() {
        const validateArr = Object.keys(this.$refs).filter(key => key.includes('custom')).map(item => this.validateSubItem(item));
        if(validateArr.length) {
          return Promise.all(validateArr).then(res => {
            if(res.includes(false)) {
              return false;
            }
            return true;
          });
        } else {
          return Promise.resolve(true);
        }
      },
      validateSubItem(formName) {
        return new Promise(resolve => {
          if(this.$refs[formName] && this.$refs[formName][0]) {
            this.$refs[formName][0].$refs.customForm.validate(valid => {
              resolve(valid);
            });
          } else {
            resolve();
          }
        });
      },
      onRemoveItem(index) {
        this.resForm.expArr.splice(index, 1);
      },
    },
    watch: {
      subResData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.length) {
            if(!this.resForm.expArr.length) {
              this.resForm.expArr = [];
              newVal.forEach(item => {
                this.resForm.expArr.push(this.$utils.deepClone(item));
              });
            }
          }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>