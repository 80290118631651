<template>
  <sub-h5-finish v-if="$utils.isPhone()" />
</template>
<script>
  import subH5Finish from '@/views/submodule/h5/guide/finish'

  export default {
    components: {
      subH5Finish
    }
  }
</script>
