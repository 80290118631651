<template>
  <div class="h5-custom-field-form">
    <!-- 长文本 -->
    <van-field
      v-if="isShowField(1)"
      v-model.trim="customForm.content"
      :required="!!fieldData.isRequired"
      :rules="rules"
      :name="`custom-1-${fieldData.fieldId}`"
      type="textarea"
      rows="4"
      autosize
      :maxlength="2000"
      placeholder="请填写"
      @change="onUpdate"
    >
      <template slot="label">
        <span>{{labelName}}</span>
        <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      </template>
    </van-field>
    <!-- 短文本 -->
    <van-field
      v-else-if="isShowField(2)"
      v-model.trim="customForm.text"
      :required="!!fieldData.isRequired"
      :rules="rules"
      :name="`custom-2-${fieldData.fieldId}`"
      :maxlength="50"
      placeholder="请填写"
      @change="onUpdate"
    >
      <template slot="label">
        <span>{{labelName}}</span>
        <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      </template>
    </van-field>
    <!-- 是否题 -->
    <van-field
      v-else-if="isShowField(3)"
      readonly
      clickable
      :name="`custom-3-${fieldData.fieldId}`"
      :required="!!fieldData.isRequired"
      :rules="rules"
      :value="customForm.flag === 1 ? '是' : customForm.flag === 0 ? '否' : ''"
      right-icon="arrow"
      placeholder="请选择"
      @click="showFlag = true"
    >
      <template slot="label">
        <span>{{labelName}}</span>
        <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      </template>
    </van-field>
    <!-- 单选 -->
    <template v-else-if="isShowField(4)">
      <van-field
        readonly
        clickable
        :name="`custom-4-${fieldData.fieldId}`"
        :required="!!fieldData.isRequired"
        :rules="rules"
        :value="customForm.singleSelectName"
        right-icon="arrow"
        placeholder="请选择"
        @click="showSelect = true"
      >
        <template slot="label">
          <span>{{labelName}}</span>
          <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
        </template>
      </van-field>
      <van-field
        v-if="customForm.singleSelect && singleList.find(i => i.value === customForm.singleSelect) && singleList.find(i => i.value === customForm.singleSelect).otherFlag"
        v-model.trim="customForm.otherContent"
        :label="''"
        :maxlength="100"
        @change="onUpdate"
        placeholder="请填写"
      />
      <van-field
        v-else-if="customForm.singleSelect && singleList.find(i => i.value === customForm.singleSelect) && singleList.find(i => i.value === customForm.singleSelect).linkBlank"
        v-model.trim="customForm.otherContent"
        :label="''"
        :maxlength="100"
        @change="onUpdate"
        placeholder="请填写"
      />
    </template>
    <!-- 多选 -->
    <template v-else-if="isShowField(5)">
      <van-field
        readonly
        clickable
        :name="`custom-5-${fieldData.fieldId}`"
        :required="!!fieldData.isRequired"
        :rules="rules"
        :value="customForm.multipleSelectNames"
        right-icon="arrow"
        placeholder="请选择"
        @click="onCheckboxSelect"
      >
        <template slot="label">
          <span>{{labelName}}</span>
          <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
        </template>
      </van-field>
      <van-field
        v-if="customForm.multipleSelect && multiList.some(i => customForm.multipleSelect.includes(i.id) && i.otherFlag)"
        v-model.trim="customForm.otherContent"
        :label="''"
        :maxlength="100"
        @change="onUpdate"
        placeholder="请填写"
      />
    </template>
    <!-- 附件-->
    <div v-else-if="isShowField(6)" class="form-item-upload">
      <van-field
        class="not-cell"
        :name="`custom-6-${fieldData.fieldId}`"
        :required="!!fieldData.isRequired"
        :rules="rules">
        <template slot="label">
          <span>{{labelName}}</span>
          <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
        </template>
        <template #input>
          <van-uploader
            v-show="!customForm.fileId && !fileList.length"
            v-model="customForm.fileList"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :accept="acceptRes"
            @oversize="onOversize"
            :after-read="afterReadRes">
            <div class="upload-btn"><i class="h5pp h5pp-icon-append"></i></div>
          </van-uploader>
          <ul v-if="customForm.fileId && fileList.length" class="file-list">
            <li>
              <p class="file-name el-sl">{{ customForm.fileList[0].fileName }}</p>
              <i class="h5pp h5pp-icon-delete" @click.stop="removeFile"></i>
            </li>
          </ul>
        </template>
      </van-field>
      <p v-if="!customForm.fileId && (!customForm.fileList || !customForm.fileList.length)" class="upload-tips">支持文档、图片、压缩包、视频、音频等格式文件，且单份文件小于50MB</p>
    </div>
    <!-- 时间选择 年 月 日 -->
    <van-field
      v-else-if="isShowField(7)"
      :rules="rules"
      :label="labelName"
      readonly 
      clickable 
      :name="`custom-7-${fieldData.fieldId}`"
      :required="!!fieldData.isRequired"
      :value="customForm.selectTimes"
      placeholder="请选择"
      right-icon="arrow"
      @click="datetimeObj.isShow = true"
    >
      <template slot="label">
        <span>{{labelName}}</span>
        <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
      </template>
    </van-field>
    <!-- 省市区详细 -->
    <template v-else-if="isShowField(8)">
      <van-field
        :rules="rules"
        :required="!!fieldData.isRequired"
        readonly 
        clickable 
        :name="`custom-8-${fieldData.fieldId}`"
        :value="customForm.addressForm.cityCode | FULLCITY"
        placeholder="请选择"
        right-icon="arrow"
        @click="addressObj.isShow = true"
      >
        <template slot="label">
          <span>{{labelName}}</span>
          <popup-big-img v-if="exampleImg" :imgSrc="exampleImg" />
        </template>
      </van-field>
      <van-field
        v-if="isCityType(4)"
        v-model.trim="customForm.addressForm.address"
        :label="''"
        :maxlength="100"
        @change="updateAddress()"
        placeholder="请填写详细地址"
      />
    </template>
    <!-- 时间段 -->
    <van-field v-if="isShowField(9)" :required="!!fieldData.isRequired" :rules="rules" :label="labelName" :name="`custom-9-${fieldData.fieldId}`" input-align="right" class="time-field">
      <template #input>
        <van-field v-model="customForm.startTimes" class="time-cell" @click="startTimeObj.isShow = true" readonly clickable input-align="left" right-icon="arrow" placeholder="开始时间" />
        <span class="separate-line">-</span>
        <van-field v-model="customForm.endTimes" class="time-cell" @click="endTimeObj.isShow = true" readonly clickable  input-align="left" right-icon="arrow" placeholder="结束时间" />
      </template>
    </van-field>

    <!-- 是否题 -->
    <van-popup v-model="showFlag" position="bottom" round>
      <van-picker class="datetime-picker-reset-bg" show-toolbar :columns="flagData" @confirm="onConfirmFlag" @cancel="showFlag = false" />
    </van-popup>
    <!--  单选 -->
    <van-popup v-model="showSelect" position="bottom" round>
      <van-picker class="datetime-picker-reset-bg" show-toolbar :columns="singleList" @confirm="onConfirmSelect" @cancel="showSelect = false" />
    </van-popup>
    <!-- 多选 -->
    <popup-checkbox v-if="currentCheckboxObj.isShow" v-model="customForm.multipleSelect" :dialogData="currentCheckboxObj" @success="successCheckData" />
    <!-- 出生年份 -->
    <popup-datetime-picker v-if="datetimeObj.isShow" :dialogData="datetimeObj" v-model="customForm.selectTimes" @change="onDateChange" />
    <!-- 省市区 -->
    <popup-select-areas v-if="addressObj.isShow" :dialogData="addressObj" v-model="customForm.cityCode" @success="onChangeExpectPosition" />
    <!-- 时间段 -->
    <popup-datetime-picker v-if="startTimeObj.isShow" :dialogData="startTimeObj" v-model="customForm.startTimes" @change="onUpdate"/>
    <popup-datetime-picker v-if="endTimeObj.isShow" :dialogData="endTimeObj" v-model="customForm.endTimes" @change="onUpdate"/>
  </div>
</template>

<script>
  import './index.scss'

  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { Field, Picker, Popup, Uploader, Toast } from 'vant'
  import popupCheckbox from '@/components/h5/dialog/checkbox'
  import popupDatetimePicker from '@/components/h5/dialog/datetimePicker'
  import popupSelectAreas from '@/components/h5/selectAreas'
  import popupBigImg from '@/components/h5/dialog/bigImg'

  export default {
    components: {
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Picker.name]: Picker,
      [Popup.name]: Popup,
      popupCheckbox,
      popupDatetimePicker,
      popupSelectAreas,
      popupBigImg
    },
    props: {
      fieldData: Object,
      itemData: [Object, String]
    },
    data() {
      return {
        isLoading: false,
        customForm: {
          text: '',
          content: '',
          singleSelect: undefined,
          singleSelectName: '',
          flag: '',
          multipleSelect: [],
          multipleSelectNames: '',
          dates: '',
          startTimes: '',
          endTimes: '',
          checkedNow: false,
          selectTimes: '',
          otherContent: '',
          addressForm: {
            province: undefined,
            city: undefined,
            district: undefined,
            cityCode: '',
            address: '',
          },
          fileId: '',
          fileList: []
        },
        addressArr: this.$store.state.locations,
        cityArr: [],
        districtArr: [],
        addressList: [],
        
        singleList: [],
        multiList: [],
        fileList: [],
        rules: [],
        currentFileObj: '',
        // exampleImg: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/BOffer/bf11eeaf7680b941be211dc2ca80ee28.jpeg',
        showFlag: false,
        flagData: [{
          text: '是',
          value: 1
        },{
          text: '否',
          value: 0
        }],
        showSelect: false,
        currentCheckboxObj: { isShow: false },
        acceptRes: 'image/png,image/jpg,image/jpeg,.pdf,.doc,.xls,.ppt,.docx,.pptx,.wps,.html,.rtf,.txt,.rar,.zip',
        datetimeObj: { 
          isShow: false,
          // 确认最小年份
          minDate: new Date(moment().year(moment().year() - 60)),
          maxDate: new Date(moment().year(moment().year() + 60))
        },
        dateTypeMap: {
          1: 'year',
          2: 'year-month',
          3: 'date'
        },
        addressObj: { isShow: false },
        startTimeObj: { 
          isShow: false,
          minDate: new Date(moment().year(moment().year() - 60)),
          maxDate: new Date(moment().year(moment().year() + 60))
        },
        endTimeObj: { 
          isShow: false,
          minDate: new Date(moment().year(moment().year() - 60)),
          maxDate: new Date(moment().year(moment().year() + 60))
        }
      }
    },
    computed: {
      ...mapGetters(['locations', 'guideInfo']),
      labelName() {
        return this.fieldData.fieldDto.name;
      },
      isShowField() {
        return (type) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.type && this.fieldData.fieldDto.type === type) return true;
          return false;
        }
      },
      isTimeType() {
        return (val) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.timeType && this.fieldData.fieldDto.timeType === val) return true;
          return false;
        }
      },
      addressSpan() {
        if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.cityType) {
          if(this.fieldData.fieldDto.cityType === 4) {
            return 5;
          } else {
            return Math.floor(24/this.fieldData.fieldDto.cityType);
          }
        } else {
          return 0;
        }
      },
      isCityType() {
        return (val) => {
          if(this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.cityType && this.fieldData.fieldDto.cityType === val) return true;
          return false;
        }
      },
      exampleImg() {
        return this.fieldData && this.fieldData.fieldDto && this.fieldData.fieldDto.fileUrls ? this.fieldData.fieldDto.fileUrls[0] || '' : '';
      }
    },
    created() {
      let triggerAction = ['blur', 'change'];
      if(this.fieldData.isRequired) {
        if([7, 9].includes(this.fieldData.fieldDto.type)) {
          triggerAction = 'change';
        }
        if(this.fieldData.fieldDto.type === 8) {
          this.rules = [{ required: true, message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction }, { validator: this.validAddress, trigger: triggerAction}]
        } else if(this.fieldData.fieldDto.type === 6) {
          this.rules = [{ required: true, message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction, validator: this.validatorUpload }]
        } else if(this.fieldData.fieldDto.type === 9) {
          this.rules = [{ message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction, validator: this.validatorTime }]
        } else {
          this.rules = [{ required: true, message: `请${this.actionName(this.fieldData.fieldDto.type)}${this.fieldData.fieldDto.name}`, trigger: triggerAction }]
        }
      }
      if(this.fieldData.fieldDto.type == 2 && this.fieldData.fieldDto.restrictionRules === 2) {
        this.rules.push({ validator: this.validNumber, message: '请输入数字', trigger: 'blur' })
      }
      if(this.fieldData.fieldDto.type == 2 && this.fieldData.fieldDto.restrictionRules === 3) {
        this.rules.push({ validator: this.validWord, message: '请输入字母', trigger: 'blur' })
      }
      if(this.fieldData.fieldDto.type === 4 && this.fieldData.fieldDto.itemDtos) {
        this.singleList = this.fieldData.fieldDto.itemDtos.map(item => {
          return {
            text: item.name,
            value: item.id,
            otherFlag: item.otherFlag,
            linkBlank: item.linkBlank,
          }
        });
      }
      if(this.fieldData.fieldDto.type === 5) {
        this.currentCheckboxObj.list = this.fieldData.fieldDto.itemDtos;
        this.currentCheckboxObj.title = this.fieldData.fieldDto.name;
      }
      if(this.fieldData.fieldDto.type === 7) {
        this.datetimeObj.title = this.fieldData.fieldDto.name;
        this.datetimeObj.type = this.dateTypeMap[this.fieldData.fieldDto.timeType] || 1;
      }
      if(this.fieldData.fieldDto.type === 8) {
        this.addressObj.level = this.fieldData.fieldDto.cityType;
      }
      if(this.fieldData.fieldDto.type === 9) {
        this.startTimeObj.type = this.dateTypeMap[this.fieldData.fieldDto.timeType] || 2;
        this.endTimeObj.type = this.dateTypeMap[this.fieldData.fieldDto.timeType] || 2;
      }
    },
    methods: {
      validatorUpload() {
        return !!(this.customForm.fileList && this.customForm.fileList.length);
      },
      validatorTime() {
        return !!(this.customForm.startTimes && this.customForm.endTimes)
      },
      onUpdate() {
        switch(this.fieldData.fieldDto.type) {
          case 1:
            this.$emit('update', { data: this.fieldData, value: this.customForm.content });
            break;
          case 2:
            this.$emit('update', { data: this.fieldData, value: this.customForm.text });
            break;
          case 3:
            this.$emit('update', { data: this.fieldData, value: this.customForm.flag });
            break;
          case 4:
            this.$emit('update', { data: this.fieldData, value: {
              itemId: this.customForm.singleSelect, 
              name: this.singleList.find(item => item.value === this.customForm.singleSelect).text,
              other: this.customForm.otherContent 
            }});
            break;
          case 5:
            let result = [];
            this.customForm.multipleSelect.forEach(id => {
              const item = this.currentCheckboxObj.list.find(item => item.id === id);
              const resItem = {
                itemId: item.id,
                name: item.name,
                englishDesc: item.englishDesc,
              };
              if(item.otherFlag) {
                resItem.other = this.customForm.otherContent;
              }
              result.push(resItem);
            });
            this.$emit('update', { data: this.fieldData, value: result });
            break;
          case 6:
            this.$emit('update', { data: this.fieldData, value: this.customForm.fileList || [] });
            break;
          case 7:
            this.$emit('update', { data: this.fieldData, value: { timeValue: this.formatTime(this.customForm.selectTimes), timeType: this.fieldData.fieldDto.timeType }});
            break;
          case 8:
            let cityCode = '';
            switch(this.fieldData.fieldDto.cityType) {
              case 1:
                cityCode = this.customForm.addressForm.province;
                break;
              case 2:
                cityCode = this.customForm.addressForm.city;
                break;
              case 3:
              case 4:
                cityCode = this.customForm.addressForm.city;
                break;
            }
            this.$emit('update', { data: this.fieldData, value: {
              cityCode: cityCode,
              address: this.customForm.addressForm.address,
              province: this.customForm.addressForm.province,
              city: this.customForm.addressForm.city,
              district: this.customForm.addressForm.district,
              cityType: this.fieldData.fieldDto.cityType
            }});
            break;
          case 9:
            this.$emit('update', { data: this.fieldData, value: { startTime: this.formatTime(this.customForm.startTimes), endTime: this.formatTime(this.customForm.endTimes), checkedNow: this.customForm.checkedNow, timeType: this.fieldData.fieldDto.timeType} });
            break;
          default:
            break;
        }
      },
      updateAddress() {
        this.onUpdate();
      },
      afterReadRes(data) {
        this.isLoading = true;
        const formData = new FormData();
        if(this.guideInfo) {
          formData.append('companyId', this.guideInfo.companyId);
        }
        formData.append('source', 21);
        formData.append('file', data.file);
        this.$http.post('/api/file/upload.json', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          isShowLoading: true
        }).then(res => {
          if(res) {
            this.fileList = [res];
            this.customForm.fileList = [res];
            this.customForm.fileId = res.id;
            this.onUpdate();
            Toast.success(`上传成功`);
          }
          this.isLoading = false;
        }).catch(res => {
          this.customForm.fileId = '';
          this.fileList = [];
          this.customForm.fileList = [];
          this.isLoading = false;
        });
      },
      onOversize() {
        Toast('文件大小不能超过 50MB');
      },
      removeFile() {
        this.customForm.fileId = '';
        this.fileList = [];
        this.customForm.fileList = [];
        this.onUpdate();
      },
      onChangeExpectPosition(data) {
        if(data[0]) {
          this.customForm.addressForm.province = data[0].value;
        }
        if(data[1]) {
          this.customForm.addressForm.city = data[1].value;
        }
        if(data[2]) {
          this.customForm.addressForm.district = data[2].value;
        }
        this.customForm.addressForm.cityCode = data[data.length - 1].value;
        this.onUpdate();
      },
      onCheckboxSelect() {
        this.currentCheckboxObj.isShow = true;
      },
      // 多选
      successCheckData(data) {
        let str = '';
        data.forEach((item, index) => {
          if(index !== data.length - 1) {
            str += `${item.name},`
          } else {
            str += item.name
          }
        });
        this.customForm.multipleSelectNames = str;
        this.onUpdate();
      },
      onConfirmSelect(picker) {
        this.customForm.singleSelect = picker.value;
        this.customForm.singleSelectName = picker.text;
        this.showSelect = false;
        this.onUpdate();
      },
      onConfirmFlag(picker) {
        this.customForm.flag = picker.value;
        this.showFlag = false;
        this.onUpdate();
      },
      onDateChange(data) {
        this.onUpdate();
      },
      formatTime(data) {
        const formatObj = {
          1: 'YYYY',
          2: 'YYYY.MM',
          3: 'YYYY.MM.DD'
        };
        if(data) {
          return moment(data).format(formatObj[this.fieldData.fieldDto.timeType])        
        } else {
          return '';
        }
      },
      actionName(type) {
        if([1, 2].includes(type)) {
          return '输入';
        } else if(type === 6) {
          return '上传';
        } else {
          return '选择';
        }
      },
      validAddress(rule, value, callback) {
        if((this.isCityType(3) || this.isCityType(4)) && (!this.customForm.addressForm.province || !this.customForm.addressForm.city || !this.customForm.addressForm.district)) {
          return false;
        } else if(this.isCityType(4) && !this.customForm.addressForm.address) {
          return false;
        } else if(this.isCityType(2) && (!this.customForm.addressForm.province || !this.customForm.addressForm.city)) {
          return false;
        } else if(this.isCityType(1) && !this.customForm.addressForm.province) {
          return false;
        }
        return true;
      },
      validNumber(rule, value, callback) {
        if(!this.customForm.text || this.customForm.text && this.customForm.text.match(/^[0-9.]+$/g)) {
          return true;
        }
        return false;
      },
      validWord(rule, value, callback) {
        if(!this.customForm.text || this.customForm.text && this.customForm.text.match(/^[a-zA-Z]+$/g)) {
          return true;
        }
        return false;
      },
      clearData() {
        this.customForm = {
          text: '',
          content: '',
          singleSelect: undefined,
          flag: '',
          multipleSelect: [],
          dates: [],
          startTimes: '',
          endTimes: '',
          checkedNow: false,
          selectTimes: '',
          otherContent: '',
          addressForm: {
            province: undefined,
            cityCode: '',
            city: undefined,
            district: undefined,
            address: '',
          },
          fileId: '',
          fileList: []
        };
      }
    },
    watch: {
      itemData: {
        handler(newVal, oldVal) {
          if(newVal && newVal.value) {
            if([4, 5, 6, 7, 8, 9].includes(newVal.fieldType)) {
              let value = JSON.parse(newVal.value);
              if(newVal.fieldType === 4 && value.itemId) {
                this.customForm.singleSelect = value.itemId;
                this.customForm.singleSelectName = value.name;
                if(value.other) {
                  this.customForm.otherContent = value.other;
                }
              }
              if(newVal.fieldType === 5 && value.length) {
                let ids = [], names= [];
                value.forEach(item => {
                  ids.push(item.itemId);
                  names.push(item.name);
                  if(item.other) {
                    this.customForm.otherContent = item.other;
                  }
                });
                this.customForm.multipleSelect = ids;
                this.customForm.multipleSelectNames = names.join(',');
              }
              if(newVal.fieldType === 6) {
                this.fileList = value;
                if(value && value[0] && value[0].id) {
                  this.customForm.fileId = value[0].id;
                }
                this.customForm.fileList = value;
              }
              if(newVal.fieldType === 7 && value.timeValue) {
                this.customForm.selectTimes = value.timeValue;
              }
              if(newVal.fieldType === 8) {
                Object.assign(this.customForm.addressForm, value);
              }
              if(newVal.fieldType === 9) {
                if(value.startTime) {
                  this.customForm.startTimes = value.startTime;
                }
                if(value.endTime) {
                  this.customForm.endTimes = value.endTime;
                }
                this.customForm.dates = [value.startTime, value.endTime];
                this.customForm.checkedNow = value.checkedNow ? true : false;
              }
            } else {
              if(newVal.fieldType === 1) {
                this.customForm.content = newVal.value;
              } else if(newVal.fieldType === 2) {
                this.customForm.text = newVal.value;
              } else if(newVal.fieldType === 3) {
                this.customForm.flag = +newVal.value;
              }
            }
          } else {
            this.clearData();
            if(newVal && newVal.fieldType === 3 && newVal.value === 0) {
              this.customForm.flag = 0;
            }
          }
        },
        immediate: true,
        deep: true
      }
    }
  }
</script>