<template>
  <div class="guide-h5-finish-wrap">
    <div class="finish-wrap">
      <img class="prompt" src="~@/assets/images/guide/img_prompt.png" />
      <p class="prompt-text">填写完成</p>
      <van-button type="primary" @click="goHome">返回首页</van-button>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Button } from 'vant';

  export default {
    components: {
      [Button.name]: Button
    },
    computed: {
      ...mapGetters(['guideInfo'])
    },
    methods: {
      goHome() {
        this.$router.push({
          name: 'guideHome',
          params: {
            url: this.guideInfo.url
          }
        })
      }
    }
  }
</script>