<template>
  <div class="guide-h5-home-wrap">
    <div class="head-nav-box">
      <van-nav-bar class="home-nav-bar">
        <template v-if="guideInfo && guideInfo.logoUrl" #title>
          <img :src="guideInfo.logoUrl" class="img-logo">
        </template>
        <template #right>
          <a href="javascript:;" class="h5pp h5pp-icon-menu" @click="showNav=true"></a>
        </template>
      </van-nav-bar>
      <!-- 挂载到 #app 节点下 -->
      <van-popup v-model="showNav" class="popup-head-navs" position="right" get-container="#app">
        <div class="navigate-wrap">
          <ul v-if="menuList && menuList.length" class="menu-box menu">
            <li v-for="(obj, key) in menuList" :key="key">
              <a v-if="obj.linkType === 1" :href="obj.linkUrl | TOLINK" target="_blank">{{ obj.name }}</a>
              <a v-else @click="goAnchor(obj.moduleId)" >{{ obj.name }}</a>
            </li>
          </ul>
        </div>
      </van-popup>
    </div>
    <div class="banner-box">
      <img :src="guideInfo.previewUrl" />
      <div class="title">
        <p class="cn">{{ guideInfo.title }}</p>
        <p v-if="guideInfo.enTitle" class="en">{{ guideInfo.enTitle }}</p>
      </div>
    </div>
    <div class="module-list">
      <div v-for="(item, index) in moduleList" :key="index" class="module-item">
        <a href="javascript:;" class="target-fix" :id="item.id"></a>
        <div class="module-title">
          <p class="cn">{{ item.name }}</p>
          <p v-if="item.englishName" class="en">{{ item.englishName }}</p>
        </div>
        <div class="module-content" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { NavBar, Popup } from 'vant'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      [NavBar.name]: NavBar,
      [Popup.name]: Popup
    },
    data() {
      return {
        showNav: false,
        menuList: [],
        moduleList: []
      }
    },
    computed: {
      ...mapGetters(['guideInfo']),
    },
    created() {
      if(this.guideInfo) {
        window.document.title = this.guideInfo.title;
      }
      this.queryDetail();
    },
    methods: {
      queryDetail() {
        const params = {};
        if(this.$route.query.previewUrl) {
          params.preUrl = this.$route.query.previewUrl;
        } else {
          params.url = this.guideInfo.url;
        }
        this.$guideHttp.get('/api/manual/guide/config.json', { params }).then(res => {
          this.menuList = res.menuList;
          this.moduleList = res.moduleList;
        });
      },
      goAnchor(id) {
        if(id && document.getElementById(id)) {
          document.getElementById(id)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
        this.showNav = false;
      }
    }
  }
</script>