<template>
  <div class="guide-h5-register-wrap" @scroll="(e) => onScroll(e)">
    <div class="resume-add-wrap">
      <div class="resume-wrap">
        <div class="register-title">
          <p class="cn">{{ registerInfo.name }}</p>
          <p v-if="registerInfo.englishName" class="en">{{ registerInfo.englishName }}</p>
        </div>
        <van-form class="res-form" ref="resForm" validate-trigger="onSubmit" scroll-to-error @failed="onFailed" @submit="onSave">
          <div class="form-filed-box">
            <van-tabs v-model:active="active" scrollspy sticky>
              <van-tab v-if="registerInfo.illustrate" title="填表说明">
                <div class="item-resume-form-box">
                  <h3>填表说明</h3>
                  <div class="content-desc" v-html="registerInfo.illustrate"></div>
                </div>
              </van-tab>
              <template v-for="(config, configIndex) in list">
                <van-tab v-if="isShowCustomModule(config)" :title="config.moduleDto.name">
                  <sub-custom-moudle v-if="!config.moduleDto.multiFlag" :key="configIndex" :ref="`customMoudle-${config.moduleId}`" :moudleData="config" :subResData="resForm.customModules ? resForm.customModules[config.moduleId] : []"></sub-custom-moudle>
                  <!-- 多段自定义内容 -->
                  <div v-else :key="configIndex" class="form-item form-module-box">
                    <h3 :class="{'require-tip': isRequired(config.fields).length}">{{config.moduleDto.name}}</h3>
                      <van-field name="customExp" :class="{'no-val-item': !customDataList(resForm.customModules[config.moduleId], config.fields).length}" :rules="[{ required: !customDataList(resForm.customModules[config.moduleId], config.fields).length && isRequired(config.fields).length, message: `${config.moduleDto.name}必填`}]">
                        <template v-if="customDataList(resForm.customModules[config.moduleId], config.fields).length" #input>
                          <div v-for="(item, key) in customDataList(resForm.customModules[config.moduleId], config.fields)" :key="'custom_'+key" class="exp-item" @click.stop="addExp(config.moduleId, resForm.customModules[config.moduleId][key], key+1)">
                            <div v-if="item[0]" class="exp-title"><span class="el-sl">{{ item[0] }}</span><i class="h5pp h5pp-icon-arrows-right"></i></div>
                            <div v-if="item[1]" class="job el-sl">{{ item[1] }}</div>
                            <div v-if="item[2]"  class="date el-sl">{{ item[2] }}</div>
                            <a href="javascript:;" class="remove-btn" @click.stop="removeItems(key, config.moduleId)"><i class="h5pp h5pp-icon-subtract"></i>删除</a>
                          </div>
                        </template>
                      </van-field>
                    <van-button native-type="button" icon="add-o" plain class="btn-add-item" @click="addExp(config.moduleId)">添加</van-button>
                  </div>
                </van-tab>
              </template>
            </van-tabs>
          </div>
          <van-tabbar>
            <van-button native-type="submit">填写完成</van-button>
          </van-tabbar>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { local } from '@/utils'
  import { mapGetters } from 'vuex'
  import { FULLCITY } from '@/utils/filters'
  import { Form, Field, Button, Tabs, Tab, Tabbar } from 'vant'
  import subCustomMoudle from '../submodule/customMoudle'

  export default {
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      [Form.name]: Form,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Tabbar.name]: Tabbar,
      subCustomMoudle
    },
    data() {
      return {
        list: [],
        registerInfo: {},
        resForm: {
          customModules: {}
        },
        active: ''
      }
    },
    computed: {
      ...mapGetters(['guideInfo']),
      isShowCustomModule() {
        return (module) => {
          return module.isOpen;
        }
      },
      customDataList() {
        return (data = [], config) => {
          let list = [];
          if(!data || !data.length) return [];
          data.forEach(moduleData => {
            let item = [];
            config.forEach(field => {
              if(field.isCheck && moduleData[field.fieldId]) {
                if(moduleData[field.fieldId].value && this.getFieldData(moduleData[field.fieldId])) {
                  item.push(this.getFieldData(moduleData[field.fieldId]));
                }
              }
            });
            if(item.length) {
              list.push(item);
            }
          });
          return list;
        }
      },
      registerId() {
        return this.$route.params.id
      }
    },
    created() {
      this.queryConfig();
      this.queryAnswer();
      if(this.guideInfo) {
        window.document.title = this.guideInfo.title;
      }
    },
    methods: {
      queryAnswer() {
        this.$guideHttp.get('/api/manual/guide/regist/answer.json', { params: {
          guideRegistId: this.registerId
        }}).then(res => {
          if(res) {
            this.resForm.customModules = res.answerMap;
          }
        });
      },
      queryConfig() {
        this.$guideHttp.get('/api/manual/guide/regist/config.json', { params: { 
          guideRegistId: this.registerId
        }}).then(res => {
          if(res && res.status && !res.delFlag) {
            this.registerInfo = res;
            this.list = res.moduleConfigList || [];
          } else {
            this.$router.replace({
              name: 'Absence'
            });
          }
        });
      },
      // 处理页面滚动
      onScroll(e) {
        this.scrollY = e.target.scrollTop;
      },
      isRequired(list) {
        return list.filter(item => item.isCheck && item.isRequired);
      },
      addExp(moduleId, obj, index) {
        let resumeForm = null;
        if(obj) {
          resumeForm = JSON.stringify(obj);
        }
        this.currentIndex = index || 0;
        let newResForm = {...this.resForm};
        local.setObject('guideResForm', newResForm);
        local.set('guideScrollY', this.scrollY);
        this.$router.push({
          name: 'guideCustomExp',
          query: {
            ...this.$route.query,
            currentIndex: this.currentIndex,
            resumeForm,
            moduleId,
            pathName: this.registerId
          }
        })
      },
      removeItems(key, moduleId) {
        this.resForm.customModules[moduleId].splice(key, 1);
        this.$forceUpdate();
        local.setObject('guideResForm', this.resForm);
      },
      getFieldData(newVal) {
        let resultVal = '';
        if(newVal && newVal.value) {
          if([4, 5, 6, 7, 8, 9].includes(newVal.fieldType)) {
            let value = JSON.parse(newVal.value);
            if(newVal.fieldType === 4 && value.itemId) {
              resultVal = value.name;
              if(value.other) {
                resultVal = value.other;
              }
            }
            if(newVal.fieldType === 5 && value.length) {
              let names= [];
              value.forEach(item => {
                let name = item.name;
                if(item.other) {
                  name = item.other;
                }
                names.push(name);
              });
              resultVal = names.join(',');
            }
            if(newVal.fieldType === 7 && value.timeValue) {
              resultVal = value.timeValue;
            }
            if(newVal.fieldType === 8) {
              resultVal = FULLCITY(value.cityCode) + value.address;
            }
            if(newVal.fieldType === 9) {
              resultVal = [value.startTime, value.endTime].join('~');
            }
            if(newVal.fieldType === 6) {
              resultVal = value?.[0]?.fileName;
            }
          } else {
            if(newVal.fieldType === 1) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 2) {
              resultVal = newVal.value;
            } else if(newVal.fieldType === 3) {
              resultVal = +newVal.value > 0 ? '是' : '否';
            }
          }
        }
        return resultVal;
      },
      customMoudle() {
        let customContent = [], moduleIds = [];
        Object.keys(this.$refs).filter(key => key.indexOf('customMoudle') !== -1).forEach(key => {
          const moduleId = +key.replace('customMoudle-', '');
          if(this.$refs[key][0].resForm.expArr.length) {
            moduleIds.push(moduleId);
            this.$refs[key][0].resForm.expArr.slice(0, 1).forEach(item => {
              if(Object.keys(item).length) {
                let fields = [];
                Object.keys(item).forEach(field => {
                  fields.push(item[field]);
                });
                customContent.push({
                  moduleId,
                  fields
                });
              }
            });
          }
        });
        if(this.resForm.customModules && Object.keys(this.resForm.customModules).length) {
          Object.keys(this.resForm.customModules).forEach(moduleId => {
            const moduleList = this.resForm.customModules[moduleId];
            if(!moduleIds.includes(+moduleId) && moduleList.length) {
              moduleList.forEach(item => {
                if(Object.keys(item).length) {
                  let fields = [];
                  Object.keys(item).forEach(field => {
                    fields.push(item[field]);
                  });
                  customContent.push({
                    moduleId,
                    fields
                  });
                }
              });
            }
          });
        }
        return customContent;
      },
      onSave() {
        this.$refs.resForm.validate().then(async (valid) => {
          this.isLoading = true;
          let resumeParams = {
            guideRegistId: this.registerId,
            json: JSON.stringify(this.customMoudle())
          };
          this.$http.post('/api/manual/guide/regist/answer/save.json', resumeParams).then(data => {
            if(data) {
              this.$router.push({
                name: 'guideFinish'
              });
            }
          }).finally(() => {
            this.isLoading = false;
          });
        });
      },
      onFailed(obj) {
        if(obj.errors && obj.errors.length) {
          console.log(obj.errors)
        }
      }
    },
    activated() {
      if(this.$route.query.field) {
        this.resForm = local.getObject('guideResForm');
        this.scrollY = local.get('guideScrollY') || 0;
        if(this.$route.query.field === 'customExp') {
          if(+this.$route.query.currentIndex && this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId][+this.$route.query.currentIndex - 1] = JSON.parse(this.$route.query[this.$route.query.field]);
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          } else if(this.$route.query[this.$route.query.field]) {
            if(this.resForm.customModules[this.$route.query.moduleId]) {
              this.resForm.customModules[this.$route.query.moduleId].push(JSON.parse(this.$route.query[this.$route.query.field]));
            } else {
              this.resForm.customModules[this.$route.query.moduleId] = [JSON.parse(this.$route.query[this.$route.query.field])];
            }
          }
        }
      } else if(!this.$route.query.field && local.getObject('guideResForm') && Object.keys(local.getObject('guideResForm')).length){
        local.remove('guideResForm');
        local.remove('guideScrollY');
      }
      this.$nextTick(() => {
        document.querySelector('.guide-h5-register-wrap').scrollTop = +this.scrollY;
      });
    },
  }
</script>