<template>
  <div class="guide-pc-home-wrap">
    <sub-header v-if="menuList && menuList.length" :menuList="menuList" :activePage="`#${menuList[0].id}`" />
    <div class="banner-box">
      <img :src="guideInfo.previewUrl" />
      <div class="title wow animate__fadeInUp">
        <p class="cn">{{ guideInfo.title }}</p>
        <p v-if="guideInfo.enTitle" class="en">{{ guideInfo.enTitle }}</p>
      </div>
    </div>
    <div class="module-list">
      <div v-for="(item, index) in moduleList" :key="index" class="module-item">
        <a :id="item.id" href="javascript:;" class="target-fix"></a>
        <div class="module-title wow animate__fadeInUp">
          <p class="cn">{{ item.name }}</p>
          <p v-if="item.englishName" class="en">{{ item.englishName }}</p>
        </div>
        <div class="module-content wow animate__fadeInUp" v-html="item.content"></div>
      </div>
    </div>
    <div class="powered-box">
      <img src="@/assets/images/img_powered.png" alt="">
    </div>
  </div>
</template>

<script>
  import 'animate.css'
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { WOW } from 'wowjs'
  import subHeader from '../submodule/header'

  export default {
    components: {
      subHeader
    },
    data() {
      return {
        menuList: [],
        moduleList: []
      }
    },
    computed: {
      ...mapGetters(['guideInfo'])
    },
    created() {
      if(this.guideInfo) {
        window.document.title = this.guideInfo.title;
      }
      this.queryDetail();
    },
    mounted() {
      this.initWow();
    },
    methods: {
      initWow() {
        this.$nextTick (() => { // 在 dom 渲染完后，再执行动画
          var wow = new WOW({
            boxClass: 'wow',  //需要执行动画的元素的 class
            animateClass: 'animate__animated', //animation.css 动画的 class
            offset: 0,  // 距离可视区域多少开始执行动画
            mobile: true,  //是否在移动设备上执行动画
            live: true //异步加载的内容是否有效
          })
          wow.init()
        });
      },
      queryDetail() {
        const params = {};
        if(this.$route.query.previewUrl) {
          params.preUrl = this.$route.query.previewUrl;
        } else {
          params.url = this.guideInfo.url;
        }
        this.$guideHttp.get('/api/manual/guide/config.json', { params }).then(res => {
          this.menuList = res.menuList;
          this.moduleList = res.moduleList;
          this.initWow();
        });
      }
    }
  }
</script>
