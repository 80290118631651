<template>
  <div class="guide-h5-login-wrap">
    <div class="login-wrap">
      <div class="login-box">
        <div class="logo">
          <img :src="guideInfo.logoUrl" />
        </div>
        <p class="welcom">{{ guideInfo.welcomeTitle }}</p>
        <van-form class="login-form" ref="loginForm" validate-trigger="onSubmit" @submit="onSave">
          <van-field
            name="phone"
            v-model.trim="loginForm.phone"
            placeholder="请输入手机号"
            autocomplete="off"
            type="number"
            clearable
            maxlength="11"
            :rules="[{required: true, message: '请输入手机号' }, { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'onBlur'}]" />
          <van-field
            name="name"
            v-model.trim="loginForm.name"
            placeholder="请输入姓名"
            autocomplete="off"
            type="text"
            clearable
            :rules="[{ required: true, message: '请输入姓名' }]" />
          <van-button class="login-btn" type="primary" round :loading="isBtnLoading" native-type="submit">登录</van-button>
        </van-form>
        <div class="company-info">
          <p class="company">{{ guideInfo.companyName }}</p>
          <p class="pinpin">聘聘云提供技术支持</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex';
  import { Form, Field, Button, Toast } from 'vant';

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button
    },
    data() {
      return {
        isBtnLoading: false,
        loginForm: {
          phone: '',
          name: ''
        }
      }
    },
    computed: {
      ...mapGetters(['guideInfo'])
    },
    created() {
      window.document.title = this.guideInfo.title;
    },
    methods: {
      onSave() {
        this.$refs.loginForm.validate().then(() => {
          this.isBtnLoading = true;
          this.$guideHttp.post('/api/manual/guide/login.json', {
            ...this.loginForm,
            url: this.guideInfo.url
          }).then(data => {
            if(data) {
              this.$store.commit('setGuideUser', data);
              // window.location.href = `https://vip.pinpin.com/guide/${this.guideInfo.url}`;
              this.$router.push({
                name: 'guideHome',
                params: {
                  url: this.guideInfo.url
                }
              })
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>