<template>
  <div class="guide-pc-register-wrap">
    <div class="register-box">
      <div class="register-title">
        <div class="title">
          <div class="cn">{{ registerInfo.name }}</div>
          <div v-if="registerInfo.englishName" class="en">{{ registerInfo.englishName }}</div>
        </div>
        <div class="logo">
          <img :src="guideInfo.logoUrl" />
        </div>
      </div>
      <div class="register-content">
        <div class="right-side">
          <div v-if="registerInfo.illustrate" class="side-item" :class="{active: currentMenuHash === '001'}" @click="addRouteHash(`#module_001`)">
            <div class="cn">填表说明</div>
          </div>
          <template v-for="(item, index) in list">
            <div v-if="isShowCustomModule(item)" class="side-item" :class="{active: currentMenuHash === String(item.moduleDto.id)}" :key="index" @click="addRouteHash(`#module_${item.moduleDto.id}`)">
              <div class="cn">{{ item.moduleDto.name }}</div>
              <div v-if="item.moduleDto.englishDesc" class="en">{{ item.moduleDto.englishDesc }}</div>
            </div>
          </template>
        </div>
        <div class="left-content">
          <div v-if="registerInfo.illustrate" class="module-item explain js_module_item" id="module_001">
            <div class="module-title">
              <div class="cn">填表说明</div>
            </div>
            <div class="module-content" v-html="registerInfo.illustrate"></div>
          </div>
          <template v-for="(item, index) in list">
            <div v-if="isShowCustomModule(item)" :key="index" class="module-item js_module_item" :id="`module_${item.moduleId}`">
              <sub-custom-moudle :ref="`customMoudle-${item.moduleId}`" :moudleData="item" :subResData="resForm.customModules ? resForm.customModules[item.moduleId] : []" />
            </div>
          </template>
          <div class="form-item-btn">
            <el-button class="btn-href" @click="onSave">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示信息"
      :visible.sync="dialogVisible"
      class="finish-dialog-wrap"
      width="500px">
      <img class="prompt" src="~@/assets/images/guide/img_prompt.png" />
      <p class="prompt-text">填写完成</p>
      <el-button type="primary" @click="goHome">返回首页</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters  } from 'vuex'
  import { Button, Message, MessageBox, Dialog } from 'element-ui'
  import subCustomMoudle from '../submodule/customMoudle'

  export default {
    components: {
      subCustomMoudle,
      [Button.name]: Button,
      [Message.name]: Message,
      [MessageBox.name]: MessageBox,
      [Dialog.name]: Dialog
    },
    data() {
      return {
        list: [],
        resForm: {
          customModules: {}
        },
        currentMenuHash: '001',
        deltaHeight: 273,
        dialogVisible: false,
        registerInfo: {}
      }
    },
    computed: {
      ...mapGetters(['guideInfo']),
      isShowCustomModule() {
        return (module) => {
          return module.isOpen;
        }
      },
      registerId() {
        return this.$route.params.id
      }
    },
    created() {
      // 兼容IE scrollTo
      if (!document.body.scrollTo) {
        Element.prototype.scrollTo = function(option) {
          this.scrollLeft = option.left;
          this.scrollTop = option.top;
        };
      }
      if(this.guideInfo) {
        window.document.title = this.guideInfo.title;
      }
      this.queryConfig();
      this.queryAnswer();
    },
    mounted() {
      document.addEventListener('scroll', this.onScrollPage, true);
    },
    beforeDestroy() {
      document.removeEventListener('scroll', this.onScrollPage);
    },
    methods: {
      queryAnswer() {
        this.$guideHttp.get('/api/manual/guide/regist/answer.json', { params: {
          guideRegistId: this.registerId
        }}).then(res => {
          if(res) {
            this.resForm.customModules = res.answerMap;
          }
        });
      },
      queryConfig() {
        this.$guideHttp.get('/api/manual/guide/regist/config.json', { params: { 
          guideRegistId: this.registerId
        }}).then(res => {
          if(res && res.status && !res.delFlag) {
            this.registerInfo = res;
            this.list = res.moduleConfigList || [];
          } else {
            this.$router.replace({
              name: 'Absence'
            });
          }
        });
      },
      onScrollPage() {
        const items = document.querySelectorAll('.js_module_item');
        if(!items.length) return;
        let offsetList = [];
        items.forEach(obj => {
          let top = obj.getBoundingClientRect().top;
          offsetList.unshift({
            id: '#'+obj.id,
            top: top - this.deltaHeight
          });
        });
        let minusOffsetList = offsetList.filter(item => item.top < 0);
        if(minusOffsetList && minusOffsetList.length) {
          this.currentMenuHash = minusOffsetList[0].id?.replace('#module_', '');
        }
      },      
      // 定位到特定项
      addRouteHash(hashId) {
        if(!document.querySelector(hashId)) {
          return;
        }
        this.$nextTick(() => {
          let top = document.querySelector(hashId).offsetTop - document.querySelector(hashId).scrollTop - this.deltaHeight + 5;
          document.querySelector('.left-content').scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
          });
        });
      },
      validateCustomForm(moduleName) {
        return new Promise(resolve => {
          if(this.$refs[moduleName] && this.$refs[moduleName][0]) {
            this.$refs[moduleName][0].validateCustom().then(valid => {
              resolve(valid);
            });
          } else {
            resolve();
          }
        });
      },
      // 校验自定义模块
      validateCustomeMoudle() {
        const validateArr = Object.keys(this.$refs).filter(key => key.includes('customMoudle')).map(item => this.validateCustomForm(item));
        if(validateArr.length) {
          return Promise.all(validateArr).then(res => {
            if(res.includes(false)) {
              return false;
            }
            return true;
          });
        } else {
          return Promise.resolve();
        }
      },
      customMoudle() {
        let customContent = [];
        Object.keys(this.$refs).filter(key => key.indexOf('customMoudle') !== -1).forEach(key => {
          const moduleId = +key.replace('customMoudle-', '');
          if(this.$refs[key][0].resForm.expArr.length) {
            this.$refs[key][0].resForm.expArr.forEach(item => {
              if(Object.keys(item).length) {
                let fields = [];
                Object.keys(item).forEach(field => {
                  fields.push(item[field]);
                });
                customContent.push({
                  moduleId,
                  fields
                });
              }
            });
          }
        });
        return customContent;
      },
      scrollFirstError() {
        this.$nextTick(() => {
          if(document.querySelector('.is-error')) {
            document.querySelector('.is-error').scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        });
      },
      onSave() {
        this.validateCustomeMoudle().then(result => {
          if(!result) {
            // 滚动到第一个错误位置
            this.scrollFirstError();
            return;
          }
          let resumeParams = {
            guideRegistId: this.registerId,
            json: JSON.stringify(this.customMoudle())
          };
          if(this.guideUser?.mockFlag) {
            Message.info('预览状态下无法提交登记表');
            return;
          }
          this.$http.post('/api/manual/guide/regist/answer/save.json', resumeParams).then(data => {
            if(data) {
              this.dialogVisible = true;
            }
          }).finally(() => {
            this.isLoading = false;
          });
        });
      },
      goHome() {
        this.dialogVisible = false;
        this.$router.push({
          name: 'guideHome',
          params: {
            url: this.guideInfo.url
          }
        })
      }
    }
  }
</script>