<template>
  <div class="viewer-img-box">
    <viewer :options="{navbar: false, title: false, toolbar: false}" :images="[imgSrc]" @inited="inited" class="h5-big-img">
      <div class="viewer-inner" @click.stop="show">
        <img class="head-img" :src="imgSrc" crossOrigin="anonymous">
        <a href="javascript:;" class="zoom-box"><i class="h5pp h5pp-icon-zoom" /></a>
      </div>
    </viewer>
  </div>
</template>

<script>
  import './index.scss'
  import 'viewerjs/dist/viewer.css'
  import { component as Viewer } from 'v-viewer'

  export default {
    components: {
      Viewer
    },
    props: {
      imgSrc: {
        type: String
      }
    },
    methods: {
      inited(viewer) {
        this.$viewer = viewer;
      },
      show() {
        this.$viewer.show();
      }
    }
  }
</script>