<template>
  <sub-h5-register v-if="$utils.isPhone()" />
  <sub-pc-register v-else />
</template>
<script>
  import subH5Register from '@/views/submodule/h5/guide/register'
  import subPcRegister from '@/views/submodule/pc/guide/register'

  export default {
    components: {
      subH5Register,
      subPcRegister
    }
  }
</script>
