<template>
  <div class="guide-pc-login-wrap">
    <div class="login-wrap">
      <div class="login-box">
        <div class="logo">
          <img :src="guideInfo.logoUrl" />
        </div>
        <p class="welcom">{{ guideInfo.welcomeTitle }}</p>
        <el-form :model="loginForm" ref="loginForm" class="login-form">
          <el-form-item
            prop="phone"
            clearable
            :rules="[{required: true, message: '请输入手机号'}, { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur'}]">
            <el-input v-model.trim="loginForm.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
          </el-form-item>
          <el-form-item
            prop="name"
            :rules="[{ required: true, message: '请输入姓名' }]"
            clearable
            class="verify-code">
            <el-input v-model.trim="loginForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-button type="primary" @click="onSave">登录</el-button>
        </el-form>
      </div>
      <div class="powered-box">
        <img src="@/assets/images/img_powered.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, FormItem, Input, Button, Message } from 'element-ui'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Message.name]: Message
    },
    data() {
      return {
        loginForm: {
          phone: '',
          name: ''
        }
      }
    },
    computed: {
      ...mapGetters(['guideInfo'])
    },
    created() {
      window.document.title = this.guideInfo.title;
    },
    methods: {
      onSave() {
        this.$refs.loginForm.validate(valid => {
          if(!valid) {
            return;
          }
          this.isBtnLoading = true;
          this.$guideHttp.post('/api/manual/guide/login.json', {
            ...this.loginForm,
            url: this.guideInfo.url
          }).then(data => {
            if(data) {
              this.$store.commit('setGuideUser', data);
              this.$router.push({
                name: 'guideHome',
                params: {
                  url: this.guideInfo.url
                }
              })
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>