<template>
  <sub-h5-home v-if="$utils.isPhone()" />
  <sub-pc-home v-else />
</template>
<script>
  import subH5Home from '@/views/submodule/h5/guide/home'
  import subPcHome from '@/views/submodule/pc/guide/home'

  export default {
    components: {
      subH5Home,
      subPcHome
    }
  }
</script>
